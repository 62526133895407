.header {
  width: 0%;
  top: 0;
  position: fixed;
  left: 0;
  z-index: 5;
  display: flex;
  justify-content: flex-end;
  background-color: var(--accent-tan);
  border-bottom: 4px solid black;
  opacity: 0;
  padding: 16px 0;
}

.header-menu-container {
  display: flex;
  height: 100%;
  align-items: center;
}

.nav-link {
  margin-left: 16px;
  font-size: 24px;
  background-color: none;
  text-decoration: none;
  cursor: pointer;
  color: black;
  transition: all 0.3s ease;
}

.nav-link:hover {
  cursor: pointer;
  text-decoration: underline;
  letter-spacing: 1.5px;
}

@media screen and (max-width: 768px) {
  .header-menu-container {
    display: flex;
    width: 100%;
  }

  .nav-link {
    font-size: 16px;
    margin-left: 8px;
  }

  .nav-link:hover {
    text-decoration: none;
    cursor: default;
    letter-spacing: -0.75px;
  }
}
