.landing-container {
  height: 100dvh;
  width: 100vw;
  position: relative;
  overflow: hidden;
  padding-top: var(--header-height);
}

.landing-content {
  opacity: 0;
  position: relative;
  top: -325px;
  width: 100vw;
  height: 120%;
}

.landing-title {
  margin: 0;
  height: 102px;
  font-weight: 600;
  color: var(--accent-red);
  font-size: 135px;
  line-height: 0.8;
}

.names {
  display: block;
  position: relative;
  width: 150%;
  height: 120px;
  overflow: hidden;
}

.name {
  display: block;
  position: absolute;
  top: 0;
  left: 15vw;
  mix-blend-mode: multiply;
  text-align: center;
}

.nameBlue {
  color: var(--accent-blue);
  position: absolute;
  top: 8px;
  left: 15vw;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .landing-content {
    width: 150%;
    height: 135%;
    top: -120px;
    overflow: hidden;
  }

  .landing-title {
    height: 70px;
    font-size: 85px;
  }

  .names {
    display: block;
    position: relative;
    width: 150%;
    height: 80px;
    overflow: hidden;
  }

  .name {
    left: 5vw;
  }

  .nameBlue {
    top: 5px;
    left: 5vw;
  }
}
