.about-container {
  height: auto;
  min-height: 100svh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--accent-tan);
  padding-top: var(--header-height);
}

.about-animation {
  width: 100vw;
  height: calc(100svh - var(--header-height));
  svg {
    position: absolute;
    top: var(--header-height);
    right: 0;
    width: 100vw;
    height: calc(100svh - var(--header-height));
  }
}

.circles circle {
  mix-blend-mode: multiply;
  fill: var(--accent-red);
}

.about-text-container {
  position: fixed;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
  top: var(--header-height);
  left: 0;
  opacity: 0;
  padding: 16px;
  mix-blend-mode: difference;
}

.about-text-container-inner {
  width: 600px;
  text-align: left;
  font-size: 24px;
  color: var(--accent-white);
  padding-bottom: var(--header-height);
}

.about-title {
  color: var(--accent-white);
  position: absolute;
  bottom: 0;
  right: 16px;
  font-size: 96px;
  margin: 0;
  overflow: hidden;
  mix-blend-mode: difference;
}

@media screen and (max-width: 768px) {
  .about-container {
    height: auto;
    min-height: 100svh;
    overflow-y: auto;
  }

  .about-animation {
    width: 100vw;
    height: calc(100svh - var(--header-height));
  }

  .about-title {
    display: none;
  }

  .about-text-container-inner {
    width: 100%;
    font-size: 24px;
    p {
      margin: 0 0 16px 0;
    }
  }
}
