.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 90;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal-content {
  width: 450px;
  max-height: 85vh;
  background-color: var(--accent-tan);
  border: 3px solid black;
  box-shadow: 0 0 3.5px 5px rgba(0, 0, 0, 0.25);
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-header {
  padding: 8px;
  border-bottom: 3px solid black;
  overflow: hidden;
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  color: black;
  z-index: 1000;
  background-color: transparent;
  border: none;
  padding: 5px;
  transition: all 0.3s ease-in-out;
}

.close-button:hover {
  scale: 1.25;
}

.modal-title {
  width: 85%;
  font-size: 32px;
  margin: 0;
  color: black;
  text-wrap: balance;
  line-height: 1;
  word-break: break-word;
}

.modal-link-container {
  padding-top: 8px;
}

.modal-link {
  font-size: 16px;
  transition: all 0.3s ease-in-out;
  color: black;
  padding-bottom: 8px;
  margin: 0;
  cursor: pointer;
  &:hover {
    letter-spacing: 1.5px;
    text-decoration: underline;
  }
}

.modal-body {
  overflow-y: auto;
  color: black;
  text-wrap: balance;
  font-size: 16px;
  line-height: 1.25;
  padding: 8px;
  p {
    margin: 0;
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  .modal-content {
    width: 350px;
    height: 70vh;
  }

  .modal-title {
    font-size: 40px;
  }

  .modal-description {
    width: 100%;
  }

  .modal-website-link {
    font-size: 18px;
    &:hover {
      cursor: default;
      letter-spacing: -0.75px;
    }
  }
}
