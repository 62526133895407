@mixin cuboid($cWidth, $cHeight, $cDepth, $cColor) {
  position: relative;
  width: $cWidth;
  height: $cHeight;
  transform-style: preserve-3d;

  .face {
    position: absolute;
    left: 0;
    top: 0;
    background-color: $cColor;
  }

  .face--front {
    width: $cWidth;
    height: $cHeight;
    transform: translateZ(calc(#{$cDepth}/ 2));
  }

  .face--back {
    width: $cWidth;
    height: $cHeight;
    transform: translateZ(calc(#{$cDepth}/ 2 * -1)) rotateY(180deg)
      rotate(180deg);
  }

  .face--left {
    width: $cDepth;
    height: $cHeight;
    transform: translateX(calc(#{$cDepth}/ 2 * -1)) rotateY(-90deg);
  }

  .face--right {
    width: $cDepth;
    height: $cHeight;
    transform: translateX(calc(#{$cWidth} - #{$cDepth}/ 2)) rotateY(90deg);
  }

  .face--top {
    width: $cWidth;
    height: $cDepth;
    transform: translateY(calc(#{$cDepth}/ 2 * -1)) rotateX(90deg);
  }

  .face--bottom {
    width: $cWidth;
    height: $cDepth;
    transform: translateY(calc(#{$cHeight} - #{$cDepth}/ 2)) rotateX(-90deg);
  }
}

/*  ==========================================================
	MAIN
	========================================================== */

$accent: black;
$background: var(--accent-tan);

.projects-container {
  position: relative;
  padding-top: var(--header-height);
  width: 100vw;
  height: 100svh;
  overflow: hidden;
}

.projects {
  width: auto;
  height: auto;
  overflow-x: hidden;
}

.bar {
  position: relative;
  left: 0;
  z-index: 2;
  overflow-x: hidden;
}

.bar--1 {
  height: 6.125em;
}

.bar--2 {
  height: 7.5em;
}

.bar--3 {
  height: 9.125em;
}

.bar--4 {
  height: 10.9375em;
}

.word {
  position: absolute;
  margin: 0;
  color: $background;
  line-height: 1;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .highlight {
    font-size: 0.4em;
    text-transform: lowercase;
    opacity: 0.9;
  }
}

.cuboid {
  position: absolute;
  will-change: transform;
  backface-visibility: hidden;
}

.cuboid--1 {
  @include cuboid(30vw, 6.625em, 6.625em, $accent);

  .word {
    bottom: 0.152em;
    left: 0.375em;
    font-size: 56px;
  }
}

.cuboid--2 {
  @include cuboid(35vw, 7.875em, 7.875em, $accent);

  .word {
    bottom: 0.311em;
    left: 0.298em;
    font-size: 66px;
  }
}

.cuboid--3 {
  @include cuboid(40vw, 9.4375em, 9.4375em, $accent);

  .word {
    bottom: 0.199em;
    left: 0.233em;
    font-size: 78px;
  }
}

.cuboid--4 {
  @include cuboid(45vw, 11.1875em, 11.1875em, $accent);

  .word {
    bottom: 0.22em;
    left: 0.183em;
    font-size: 91px;
  }
}

.cuboid--5 {
  @include cuboid(50vw, 13.125em, 13.125em, $accent);

  .word {
    bottom: 0.243em;
    left: 0.162em;
    font-size: 103px;
  }
}

.face {
  position: relative;
  overflow: hidden;
}

.base {
  width: 100%;
  height: 100%;
  background: $background;
}

.project-line {
  position: relative;
  display: block;
  z-index: 10;
  margin: 0;
  button {
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
  }
}

.project-line__txt {
  margin: 0;
  opacity: 0;
  display: flex;
  transition: all 0.3s ease-in-out;
  text-align: right;
  color: black;
  font-size: 1.625em;
  font-weight: 600;
  line-height: 1;
  text-transform: lowercase;
  display: flex;
  align-items: center;
  gap: 0.5em;

  span {
    margin: 0;
    padding: 0;
  }
}

.project-line__txt:hover {
  letter-spacing: 1.5px;
  text-decoration: underline;
  cursor: pointer;
}

.project-line__bg {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: $accent;
  display: block;
}

.projects-list {
  position: absolute;
  z-index: 2;
  right: 1em;
  top: 9.375em;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1.5em;

  .info {
    position: relative;
  }
}

@media (max-width: 768px) {
  .cuboid--1 {
    @include cuboid(40vw, 6.625em, 6.625em, $accent);

    .word {
      bottom: 0.4em;
      left: 0.457em;
      font-size: 42px;
      letter-spacing: -1px;

      .highlight {
        font-size: 0.35em;
      }
    }
  }

  .cuboid--2 {
    @include cuboid(50vw, 7.875em, 7.875em, $accent);

    .word {
      bottom: 0.524em;
      left: 0.381em;
      font-size: 50px;
      letter-spacing: -1px;

      .highlight {
        font-size: 0.35em;
      }
    }
  }

  .cuboid--3 {
    @include cuboid(60vw, 9.4375em, 9.4375em, $accent);

    .word {
      bottom: 0.408em;
      left: 0.327em;
      font-size: 59px;
      letter-spacing: -1px;

      .highlight {
        font-size: 0.35em;
      }
    }
  }

  .cuboid--4 {
    display: none;
  }

  .cuboid--5 {
    display: none;
  }

  .project-line {
    button {
      font-size: 35px;
      line-height: 1;
      color: black;
      text-shadow: none;
      text-wrap: nowrap;
    }
  }

  .projects-list {
    right: auto;
    top: auto;
    bottom: 1em;
    gap: 0.5em;
    align-items: flex-start;
    padding-left: 1em;
    .project-line__txt {
      text-align: left;
      font-size: 1.575em;
      &:hover {
        text-decoration: none;
        cursor: default;
        letter-spacing: -0.75px;
      }
    }
  }
}
