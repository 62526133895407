.skills-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100svh;
  padding-top: var(--header-height);
  overflow: hidden;
  position: relative;
}

.stage {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
