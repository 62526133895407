body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  background-color: var(--accent-tan);
  scroll-behavior: smooth;
  font-family: neue-haas-grotesk-display, "Helvetica Neue", Helvetica,
    sans-serif !important;
  font-weight: 600;
  text-transform: lowercase;
  color: white;
  overflow-x: hidden;
  font-display: block;
  letter-spacing: -0.75px;
  font-style: normal;
  text-underline-offset: 4px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --accent-red: #b90207;
  --accent-blue: #019ac5;
  --accent-light-blue: #00c5f8;
  --accent-white: #f1f3ee;
  --accent-pink: #e3006d;
  --accent-tan: #dbe0cf;
  --accent-yellow: #ffdd00;
  --accent-purple: #652ec7;
  --accent-green: #7fff00;
}
