.contact-container {
  overflow: hidden;
  width: 100vw;
  height: 100dvh;
  background-color: var(--accent-tan);
}

.contact-animation {
  background: transparent;
  width: 100vw;
  height: 100svh;
}

.title-wrap {
  position: absolute;
  top: 166px;
  left: 16px;
}

.contact-title {
  margin: 0;
  font-family: neue-haas-grotesk-display, "Helvetica Neue", Helvetica,
    sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 96px;
  color: black;
  line-height: 1.3;
  z-index: 5;
  mix-blend-mode: difference;
  filter: invert(1);
}

.links-wrap {
  position: absolute;
  top: 240px;
  right: 16px;
}

.contact-links {
  display: flex;
  flex-direction: row;
  font-size: 24px;
  text-decoration: none;
  cursor: pointer;
  mix-blend-mode: difference;
  filter: invert(1);
  gap: 16px;
  a {
    color: black;
    text-decoration: none;
    transition: letter-spacing 0.3s ease;

    &:hover {
      letter-spacing: 1.5px;
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 768px) {
  .title-wrap {
    top: 170px;
  }

  .contact-title {
    font-size: 64px;
  }

  .links-wrap {
    top: 160px;
    left: 16px;
  }

  .contact-links {
    span {
      margin-right: 8px;
    }
  }

  .contact-links {
    gap: 8px;
    a {
      &:hover {
        text-decoration: none;
        cursor: default;
        letter-spacing: -0.75px;
      }
    }
  }
}

.square__large {
  z-index: 5;
}

.ball {
  z-index: 2;
  visibility: hidden;
}
